import React, { Fragment, Suspense, useEffect, useState } from 'react'
import { isAuthenticated } from './services/authentication'
import env from './model/environment'

const Background = React.lazy(() => import('./components/background'))
const Navbar = React.lazy(() => import('./components/navbar'))
const Panel = React.lazy(() => import('./components/panel'))

const AppContent = () => <Fragment>
  <Suspense fallback=''>
    <Background />
    <Navbar />
    <Panel />
  </Suspense>
</Fragment>

AppContent.propTypes = {}

const App = () => {
  const [userIsAuthenticated, setUserIsAuthenticated] = useState(false)

  useEffect(() => {
    isAuthenticated().then(isAuthenticated => {
      if (!isAuthenticated) {
        window.location = env.REACT_APP_DOMAIN + 'authentication/'
      } else {
        setUserIsAuthenticated(true)
      }
    })
  })

  return <Fragment>
    {userIsAuthenticated && <AppContent />}
  </Fragment>
}

App.propTypes = {}

export default App
