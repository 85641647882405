import env from '../model/environment'

export const isAuthenticated = async () => {
  const cookies = getCookies()
  if (cookies.accessToken) {
    const response = await fetch(env.REACT_APP_DOMAIN + 'authentication/api/is-authorized', {
      credentials: 'include',
      method: 'POST'
    })
    return response.status === 200
  }
  if (cookies.refreshToken && !cookies.accessToken) {
    const response = await fetch(env.REACT_APP_DOMAIN + 'authentication/api/access-token', {
      credentials: 'include',
      method: 'POST'
    })
    if (response.status === 200) {
      const body = await response.json()
      setCookie('accessToken', body.accessToken, '5 minutes')
      return true
    }
  }
  return false
}

export const needRefresh = async () => {
  const cookies = getCookies()
  if (cookies.refreshToken && !cookies.accessToken) {
    const response = await fetch(env.REACT_APP_DOMAIN + 'authentication/api/access-token', {
      credentials: 'include',
      method: 'POST'
    })
    if (response.status === 200) {
      const body = await response.json()
      setCookie('accessToken', body.accessToken, '5 minutes')
    }
  } else if (!cookies.refreshToken) {
    window.location = env.REACT_APP_DOMAIN + 'authentication/?origin=reminder/'
  }
}

const getCookies = () => {
  const cookies = {}
  document.cookie
    .split('; ')
    .forEach(s => {
      const split = s.split('=')
      cookies[split[0]] = split[1]
    })
  return cookies
}

const setCookie = (cname, cvalue, expirationDuration) => {
  const d = new Date()
  d.setTime(d.getTime() + getExpirationDuration(expirationDuration))
  const expires = d.toUTCString()
  document.cookie = `${cname}=${cvalue};expires=${expires};path=/`
  document.cookie = `expiration-${cname}=${expires};expires=${expires};path=/`
}

const getExpirationDuration = expirationDuration => {
  const splitteduration = expirationDuration.split(' ')
  const duration = parseInt(splitteduration[0], 10)
  const getUnit = unit => {
    switch (unit) {
      case 'millisecond':
      case 'milliseconds':
        return 1
      case 'second':
      case 'seconds':
        return 1000
      case 'minute':
      case 'minutes':
        return 60 * 1000
      case 'hour':
      case 'hours':
        return 60 * 60 * 1000
      case 'day':
      case 'days':
        return 24 * 60 * 60 * 1000
      default:
        return 0
    }
  }
  return duration * getUnit(splitteduration[1])
}
